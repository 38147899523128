import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { adminPost } from '../../utils';
import { URLS } from '../../constants';
import { FaDollarSign, FaEdit } from 'react-icons/fa';

// import getFormattedDate from './utils';
import RewardsGraph from '../../components/RewardsGraph';
import SmallSpinner from '../../components/SmallSpinner';
import { fetchPriceHistory, convertBonestoUSD } from './utils';

const ManageDevices = () => {
  const [devices, setDevices] = useState([]);
  const [devicesLoading, setDevicesLoading] = useState(true);

  const [revenueData, setRevenueData] = useState(null);
  const [rewards, setRewards] = useState([]);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [revenueModalOpen, setRevenueModalOpen] = useState(false);

  const [newDevice, setNewDevice] = useState({
    name: '',
    serialNumber: '',
    macAddress: '',
    clientEmail: '',
    installerEmail: '',
    status: 'INVENTORY',
    type: 'wifi',
  });

  const [updateDevice, setUpdateDevice] = useState({
    id: '',
    name: '',
    serialNumber: '',
    macAddress: '',
    clientEmail: '',
    installerEmail: '',
    status: 'INVENTORY',
    type: 'wifi',
  });

  const [addingDevice, setAddingDevice] = useState(false);
  const [updatingDevice, setUpdatingDevice] = useState(false);

  const [isAllTimeView, setIsAllTimeView] = useState(false);

  const [priceHistory, setPriceHistory] = useState(null);

  const calculateTotalRewards = (rewardsArray) => {
    if (!rewardsArray || !Array.isArray(rewardsArray) || !priceHistory)
      return '0.00';

    return rewardsArray
      .reduce((sum, reward) => {
        const date = new Date(reward.timestamp).toLocaleDateString();
        const valueInUsd = convertBonestoUSD(reward.sum, priceHistory, date);
        return sum + valueInUsd;
      }, 0)
      .toFixed(2);
  };

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await adminPost(URLS.GET_ALL_DEVICES);
        if (!response.ok) {
          throw new Error('Failed to fetch devices');
        }
        const data = await response.json();
        setDevices(data);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
      setDevicesLoading(false);
    };
    fetchDevices();
  }, []);

  useEffect(() => {
    const getPriceHistory = async () => {
      const data = await fetchPriceHistory();
      setPriceHistory(data);
    };

    getPriceHistory();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDevice((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateDevice((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddDevice = async () => {
    setAddingDevice(true);
    try {
      const response = await adminPost(URLS.ADD_DEVICE, newDevice);
      if (!response.ok) {
        throw new Error('Failed to add device');
      }
      const addedDevice = await response.json();
      setDevices((prev) => [...prev, addedDevice.device]);
      setAddModalOpen(false);
      setNewDevice({
        name: '',
        serialNumber: '',
        macAddress: '',
        clientEmail: '',
        installerEmail: '',
        status: 'INVENTORY',
        type: 'Mobile',
      });
    } catch (error) {
      console.error('Error adding device:', error);
    } finally {
      setAddingDevice(false);
    }
  };

  const handleUpdateDevice = async () => {
    setUpdatingDevice(true);
    try {
      const response = await adminPost(URLS.UPDATE_DEVICE, updateDevice);
      if (!response.ok) {
        throw new Error('Failed to update device');
      }
      const updatedDevice = (await response.json()).device;
      setDevices((prev) =>
        prev.map((device) =>
          device.id === updatedDevice.id ? updatedDevice : device,
        ),
      );
      setUpdateModalOpen(false);
    } catch (error) {
      console.error('Error updating device:', error);
    } finally {
      setUpdatingDevice(false);
    }
  };

  const openRevenueModal = async (deviceName, deviceType) => {
    try {
      setRevenueModalOpen(true);
      const response = await fetch(
        `https://moken-backend.vercel.app/api/trpc/hotspots.search?input=%7B%22json%22%3A%7B%22name%22%3A%22${encodeURIComponent(deviceName)}%22%7D%7D`,
        {
          method: 'GET',
          headers: {
            accept: '*/*',
            'accept-language': 'en-US,en;q=0.9',
            'content-type': 'application/json',
            origin: 'https://explorer.moken.io',
            priority: 'u=1, i',
            'sec-ch-ua':
              '"Google Chrome";v="131", "Chromium";v="131", "Not_A Brand";v="24"',
            'sec-ch-ua-mobile': '?0',
            'sec-ch-ua-platform': '"macOS"',
            'sec-fetch-dest': 'empty',
            'sec-fetch-mode': 'cors',
            'sec-fetch-site': 'cross-site',
            'user-agent':
              'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36',
          },
        },
      );

      const data = await response.json();
      const deviceData = data.result.data.json.filter((hotspot) => {
        return hotspot.hotspotType === deviceType;
      })[0];

      if (deviceData) {
        setRevenueData(deviceData);
        await fetchRewardsData(deviceData.address, isAllTimeView);
      } else {
        throw new Error('No device data');
      }
    } catch (error) {
      setRevenueModalOpen(false);
      alert(`Error fetching data: ${error.message}`);
    }
  };

  const fetchRewardsData = async (address, allTime) => {
    try {
      const url = `https://moken-backend.vercel.app/api/trpc/hotspots.detailedCells?input=%7B%22json%22%3A%7B%22min_time_temporal%22%3A%222024-11-26T00%3A00%3A00-08%3A00%5BAmerica%2FLos_Angeles%5D%22%2C%22all_time%22%3A%22${allTime}%22%2C%22address%22%3A%22${encodeURIComponent(address)}%22%7D%7D`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          accept: '*/*',
          'accept-language': 'en-US,en;q=0.9',
          'content-type': 'application/json',
          origin: 'https://explorer.moken.io',
          priority: 'u=1, i',
          'sec-ch-ua':
            '"Google Chrome";v="131", "Chromium";v="131", "Not_A Brand";v="24"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'cross-site',
          'user-agent':
            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36',
        },
      });

      const data = await response.json();
      const fetchedRewards = data.result.data.json[0]?.rewards || [];
      setRewards(fetchedRewards);
    } catch (error) {
      console.error('Error fetching rewards:', error);
      setRewards([]);
    }
  };

  return (
    <div className="p-6 bg-white shadow-lg rounded-lg">
      <Link
        to="/admin/dashboard"
        className="text-blue-600 hover:text-blue-800 font-medium mb-6 inline-block"
      >
        Back To Dashboard
      </Link>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Manage Devices
      </h2>

      <button
        onClick={() => setAddModalOpen(true)}
        className="text-white bg-blue-600 hover:bg-blue-700 font-medium py-2 px-4 rounded-lg mb-6 inline-block"
      >
        Add Device
      </button>

      <hr className="border-t-2 border-gray-300 mb-6" />

      {devicesLoading ? (
        <div className="flex justify-center">
          <SmallSpinner />
        </div>
      ) : !devices.length ? (
        <p className="text-center text-gray-500">No devices.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg border border-gray-300">
            <thead className="bg-gray-100 text-left">
              <tr>
                <th />
                <th className="px-4 py-2 font-medium text-gray-600">Name</th>
                <th className="px-4 py-2 font-medium text-gray-600">Serial</th>
                <th className="px-4 py-2 font-medium text-gray-600">MAC</th>
                <th className="px-4 py-2 font-medium text-gray-600">Status</th>
                <th className="px-4 py-2 font-medium text-gray-600">Type</th>
                <th className="px-4 py-2 font-medium text-gray-600">Client</th>
                <th className="px-4 py-2 font-medium text-gray-600">
                  Installer
                </th>
              </tr>
            </thead>
            <tbody>
              {devices.map((device) => (
                <tr key={device.id} className="border-t">
                  <td className="px-4 py-2 text-gray-800">
                    <div className="flex space-x-4">
                      <button
                        onClick={() =>
                          openRevenueModal(device.name, device.type)
                        }
                        className="bg-gray-200 hover:bg-gray-300 text-green-500 hover:text-green-700 p-2 rounded-md shadow-lg transition-all duration-200"
                      >
                        <FaDollarSign />
                      </button>
                      <button
                        onClick={() => {
                          setUpdateDevice(device);
                          setUpdateModalOpen(true);
                        }}
                        className="bg-gray-200 hover:bg-gray-300 text-blue-500 hover:text-blue-700 p-2 rounded-md shadow-lg transition-all duration-200"
                      >
                        <FaEdit />
                      </button>
                    </div>
                  </td>
                  <td className="px-4 py-2 text-gray-800">{device.name}</td>
                  <td className="px-4 py-2 text-gray-800">
                    {device.serialNumber}
                  </td>
                  <td className="px-4 py-2 text-gray-800">
                    {device.macAddress}
                  </td>
                  <td className="px-4 py-2 text-gray-800">{device.status}</td>
                  <td className="px-4 py-2 text-gray-800">{device.type}</td>
                  <td className="px-4 py-2 text-gray-800">
                    {device.clientEmail}
                  </td>
                  <td className="px-4 py-2 text-gray-800">
                    {device.installerEmail}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* Add Device Modal */}
      {addModalOpen && (
        <div
          className="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center"
          onClick={() => setAddModalOpen(false)}
        >
          <div
            className="bg-white p-10 rounded-lg shadow-lg w-96 max-h-[90vh] overflow-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-xl font-semibold mb-4">Add New Device</h3>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={newDevice.name}
                onChange={handleInputChange}
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Serial Number
              </label>
              <input
                type="text"
                name="serialNumber"
                value={newDevice.serialNumber}
                onChange={handleInputChange}
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                MAC Address
              </label>
              <input
                type="text"
                name="macAddress"
                value={newDevice.macAddress}
                onChange={handleInputChange}
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Client Email
              </label>
              <input
                type="email"
                name="clientEmail"
                value={newDevice.clientEmail}
                onChange={handleInputChange}
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Installer Email
              </label>
              <input
                type="email"
                name="installerEmail"
                value={newDevice.installerEmail}
                onChange={handleInputChange}
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Status
              </label>
              <select
                name="status"
                value={newDevice.status}
                onChange={handleInputChange}
                className="w-full border rounded px-3 py-2"
              >
                <option value="INVENTORY">INVENTORY</option>
                <option value="INSTALLED">INSTALLED</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Type
              </label>
              <select
                name="type"
                value={newDevice.type}
                onChange={handleInputChange}
                className="w-full border rounded px-3 py-2"
              >
                <option value="wifi">wifi</option>
                <option value="iot">iot</option>
              </select>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setAddModalOpen(false)}
                className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleAddDevice}
                className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg"
                disabled={addingDevice}
              >
                {addingDevice ? <SmallSpinner /> : 'Add Device'}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Update Device Modal */}
      {updateModalOpen && updateDevice && (
        <div
          className="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center"
          onClick={() => {
            setUpdateModalOpen(false);
          }}
        >
          <div
            className="bg-white p-10 rounded-lg shadow-lg w-96 max-h-[90vh] overflow-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-xl font-semibold mb-4">Update Device</h3>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={updateDevice.name}
                onChange={handleUpdateInputChange}
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Serial Number
              </label>
              <input
                type="text"
                name="serialNumber"
                value={updateDevice.serialNumber}
                onChange={handleUpdateInputChange}
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                MAC Address
              </label>
              <input
                type="text"
                name="macAddress"
                value={updateDevice.macAddress}
                onChange={handleUpdateInputChange}
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Client Email
              </label>
              <input
                type="email"
                name="clientEmail"
                value={updateDevice.clientEmail}
                onChange={handleUpdateInputChange}
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Installer Email
              </label>
              <input
                type="email"
                name="installerEmail"
                value={updateDevice.installerEmail}
                onChange={handleUpdateInputChange}
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Status
              </label>
              <select
                name="status"
                value={updateDevice.status}
                onChange={handleUpdateInputChange}
                className="w-full border rounded px-3 py-2"
              >
                <option value="INVENTORY">INVENTORY</option>
                <option value="INSTALLED">INSTALLED</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Type
              </label>
              <select
                name="type"
                value={updateDevice.type}
                onChange={handleUpdateInputChange}
                className="w-full border rounded px-3 py-2"
              >
                <option value="wifi">wifi</option>
                <option value="iot">iot</option>
              </select>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setUpdateModalOpen(false);
                }}
                className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateDevice}
                className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg"
                disabled={updatingDevice}
              >
                {updatingDevice ? <SmallSpinner /> : 'Update Device'}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Revenue Modal */}
      {revenueModalOpen && revenueData && (
        <div
          className="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center"
          onClick={() => {
            setRevenueModalOpen(false);
            setRevenueData(null);
          }}
        >
          <div
            className="bg-white p-10 rounded-lg shadow-lg w-98 max-h-[90vh] overflow-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Revenue Information</h3>
              <div className="flex flex-col items-end gap-2">
                <div className="text-sm font-medium text-gray-600">
                  Total Rewards: ${calculateTotalRewards(rewards)}{' '}
                  {isAllTimeView ? '(All Time)' : '(30 Days)'}
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex items-center bg-gray-200 rounded-lg p-1">
                    <button
                      className={`px-4 py-2 rounded-lg transition-all duration-200 ${
                        !isAllTimeView
                          ? 'bg-blue-600 text-white shadow-md'
                          : 'bg-transparent text-gray-700'
                      }`}
                      onClick={async (e) => {
                        e.stopPropagation();
                        setIsAllTimeView(false);
                        await fetchRewardsData(revenueData.address, false);
                      }}
                    >
                      30 Days
                    </button>
                    <button
                      className={`px-4 py-2 rounded-lg transition-all duration-200 ${
                        isAllTimeView
                          ? 'bg-blue-600 text-white shadow-md'
                          : 'bg-transparent text-gray-700'
                      }`}
                      onClick={async (e) => {
                        e.stopPropagation();
                        setIsAllTimeView(true);
                        await fetchRewardsData(revenueData.address, true);
                      }}
                    >
                      All Time
                    </button>
                  </div>
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => {
                      setRevenueModalOpen(false);
                      setRevenueData(null);
                    }}
                  >
                    &times;
                  </button>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <p>
                <strong>Hotspot Name:</strong> {revenueData.name}
              </p>
              <p>
                <strong>Maker:</strong> {revenueData.maker}
              </p>
              <p>
                <strong>Hotspot Address:</strong> {revenueData.address}
              </p>
              <p>
                <strong>Owner:</strong> {revenueData.owner}
              </p>
              <p>
                <strong>Status:</strong> {revenueData.statusString}
              </p>
              <p>
                <strong>Hotspot Type:</strong> {revenueData.hotspotType}
              </p>
              <p className="flex gap-2">
                <strong>CBRS::</strong>
                {revenueData.capabilities?.cbrs?.toString() ?? 'Not available'}
                <strong>IoT::</strong>
                {revenueData.capabilities?.iot?.toString() ?? 'Not available'}
                <strong>Mobile::</strong>
                {revenueData.capabilities?.mobile?.toString() ??
                  'Not available'}
                <strong>WiFi::</strong>
                {revenueData.capabilities?.wifi?.toString() ?? 'Not available'}
              </p>
              <p className="flex gap-2">
                <strong>Hex::</strong>
                {revenueData.location?.hex}
                <strong>Latitude::</strong>
                {revenueData.location?.lat}
                <strong>Longitude::</strong>
                {revenueData.location?.lng}
              </p>
              <p className="flex gap-2">
                <strong>Download Speed::</strong>
                {revenueData.recent.speedtestAverage.download}
                <strong>Upload Speed::</strong>
                {revenueData.recent.speedtestAverage.upload}
                <strong>Latency::</strong>
                {revenueData.recent.speedtestAverage.latency}
                <strong>Reward Multiplier::</strong>
                {revenueData.recent.speedtestAverage.rewardMultiplier}
              </p>

              <RewardsGraph rewards={rewards} />
            </div>
            <button
              onClick={() => {
                setRevenueModalOpen(false);
                setRevenueData(null);
              }}
              className="text-white bg-red-600 hover:bg-red-700 px-4 py-2 rounded-md mt-4"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageDevices;
