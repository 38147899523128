import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { adminPost } from '../../utils';
import { POTENTIAL_SITE_STATUS, URLS } from '../../constants';
import Spinner from '../../components/Spinner';
import { getFormattedDate } from './utils';

const AssignInstallerActiveSites = () => {
  const [sites, setSites] = useState([]);
  const [sitesLoading, setSitesLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [updatedSites, setUpdatedSites] = useState([]);
  const [allInstallers, setAllInstallers] = useState([]);

  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const response = await adminPost(URLS.GET_INSTALLERS);
        if (!response.ok) {
          console.error('Failed to fetch installers');
          return;
        }
        const data = await response.json();
        setAllInstallers(data.installers);
      } catch (error) {
        console.error('Error fetching installers:', error);
      }
    };
    fetchInstallers();
  }, []);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        setSitesLoading(true);
        const response = await adminPost(URLS.GET_OPS_REVIEW_SITES, {
          page,
        });
        if (!response.ok) {
          console.error('Failed to fetch ops review sites');
          return;
        }
        const data = await response.json();
        const updatedSitesArray = data.sites.map((site) => ({
          ...site,
          installer: '',
        }));
        setUpdatedSites(updatedSitesArray);
        setSites(data.sites);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching ops review sites:', error);
      }
      setSitesLoading(false);
    };
    fetchSites();
  }, [page]);

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={`px-3 py-1 rounded-lg transition-all border ${
            i === page
              ? 'bg-blue-500 text-white border-blue-500'
              : 'bg-white text-gray-600 border-gray-300 hover:bg-gray-100 hover:text-blue-500'
          }`}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  const handleApprove = async (siteId, installer, event) => {
    event.stopPropagation();
    const updated = sites.map((site) =>
      site.id === siteId
        ? {
            ...site,
            status: POTENTIAL_SITE_STATUS.APPROVED,
            installer,
          }
        : site,
    );
    setSites(updated);
    try {
      const response = await adminPost(URLS.APPROVE_SITE, {
        siteId,
        installer,
      });
      if (!response.ok) {
        console.error('Failed to approve site');
      }
      const updatedSitesArray = updatedSites.map((site) =>
        site.id === siteId ? { ...site, installer: '' } : site,
      );
      setUpdatedSites(updatedSitesArray);
    } catch (error) {
      console.error('Error approving site:', error);
    }
  };

  const handleDeny = async (siteId, event) => {
    event.stopPropagation();
    const updated = sites.map((site) =>
      site.id === siteId
        ? {
            ...site,
            status: POTENTIAL_SITE_STATUS.REJECTED,
          }
        : site,
    );
    setSites(updated);
    try {
      const response = await adminPost(URLS.REJECT_SITE, {
        siteId,
      });
      if (!response.ok) {
        console.error('Failed to deny site');
      }
      const updatedSitesArray = updatedSites.map((site) =>
        site.id === siteId ? { ...site, installer: '' } : site,
      );
      setUpdatedSites(updatedSitesArray);
    } catch (error) {
      console.error('Error denying site:', error);
    }
  };

  const handleInstallerChange = (siteId, installerEmail) => {
    setUpdatedSites((prevSites) =>
      prevSites.map((site) =>
        site.id === siteId ? { ...site, installer: installerEmail } : site,
      ),
    );
  };

  return (
    <div className="p-4">
      <Link
        to="/admin/dashboard"
        className="text-blue-500 hover:underline mb-4 inline-block"
      >
        Back To Dashboard
      </Link>
      <h2 className="text-xl font-semibold mb-4">
        Assign Installer for Active Sites (Existing Accounts)
      </h2>
      <hr className="mb-4" />

      {sitesLoading ? (
        <Spinner />
      ) : !sites.length ? (
        <p className="text-center text-gray-500">No active sites.</p>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 text-left">Actions</th>
                  <th className="px-4 py-2 text-left">Status</th>
                  <th className="px-4 py-2 text-left">Email</th>
                  <th className="px-4 py-2 text-left">Street Address</th>
                  <th className="px-4 py-2 text-left">Daily Patrons</th>
                  <th className="px-4 py-2 text-left">Square Footage</th>
                  <th className="px-4 py-2 text-left">Created</th>
                  <th className="px-4 py-2 text-left">Installer</th>
                </tr>
              </thead>
              <tbody>
                {sites.map((site, index) => (
                  <tr key={site.id} className="border-t">
                    <td className="px-4 py-2 border-b flex items-center space-x-2">
                      <button
                        onClick={(event) =>
                          handleApprove(
                            site.id,
                            updatedSites[index].installer,
                            event,
                          )
                        }
                        disabled={
                          !updatedSites[index].installer ||
                          site.status !== POTENTIAL_SITE_STATUS.OPS_REVIEW
                        }
                        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 text-sm disabled:opacity-50"
                      >
                        Approve
                      </button>
                      <button
                        onClick={(event) => handleDeny(site.id, event)}
                        disabled={
                          site.status !== POTENTIAL_SITE_STATUS.OPS_REVIEW
                        }
                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 text-sm disabled:opacity-50"
                      >
                        Deny
                      </button>
                      <select
                        onClick={(event) => event.stopPropagation()}
                        onChange={(e) =>
                          handleInstallerChange(site.id, e.target.value)
                        }
                        value={updatedSites[index].installer}
                        disabled={
                          site.status !== POTENTIAL_SITE_STATUS.OPS_REVIEW
                        }
                        className="p-1.5 border rounded-md text-sm"
                      >
                        <option value="">Select Installer</option>
                        {allInstallers.map((installer) => (
                          <option key={installer.email} value={installer.email}>
                            {installer.email}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="px-4 py-2">{site.status}</td>
                    <td className="px-4 py-2">{site.email}</td>
                    <td className="px-4 py-2">{site.address}</td>
                    <td className="px-4 py-2">{site.dailyPatrons}</td>
                    <td className="px-4 py-2">{site.squareFootage}</td>
                    <td className="px-4 py-2">
                      {getFormattedDate(site.created)}
                    </td>
                    <td className="px-4 py-2">{site.installer}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={prevPage}
              disabled={page === 1}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded disabled:opacity-50"
            >
              Prev
            </button>
            <div className="flex space-x-2">{renderPageNumbers()}</div>
            <button
              onClick={nextPage}
              disabled={page === totalPages}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AssignInstallerActiveSites;
