import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { getFormattedDate } from './utils';
import { adminPost } from '../../utils';
import {
  CLIENT_TYPE,
  POTENTIAL_CLIENT_STATUS,
  ROLE,
  URLS,
} from '../../constants';
import CustomModal from '../../components/CustomModal';
import EditPotentialBusiness from '../../components/EditPotentialBusiness';

const PotentialBusinessClients = () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [commentsLoading, setCommentsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [filters, setFilters] = useState({
    myClients: true,
    unclaimed: true,
    othersClients: true,
  });
  const [appliedFilters, setAppliedFilters] = useState({
    myClients: true,
    unclaimed: true,
    othersClients: true,
  });
  const [sendingToOps, setSendingToOps] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [allInstallers, setAllInstallers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setUsersLoading(true);
        const response = await adminPost(URLS.GET_POTENTIAL_BUSINESSES, {
          page,
          filters: appliedFilters,
        });
        if (!response.ok) {
          console.error('Failed to fetch potential businesses');
          return;
        }
        const data = await response.json();
        setUsers(data.users);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching potential businesses:', error);
      }
      setUsersLoading(false);
    };
    fetchUsers();
  }, [appliedFilters, page]);

  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const response = await adminPost(URLS.GET_INSTALLERS);
        if (!response.ok) {
          console.error('Failed to fetch installers');
          return;
        }
        const data = await response.json();
        setAllInstallers(data.installers);
      } catch (error) {
        console.error('Error fetching installers:', error);
      }
    };
    fetchInstallers();
  }, []);

  const handleCheckboxChange = (userId) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(userId)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== userId);
      } else {
        return [...prevSelectedIds, userId];
      }
    });
  };

  const handleSendToOps = async () => {
    setSendingToOps(true);
    try {
      const response = await adminPost(URLS.SEND_TO_OPS, {
        userIds: selectedIds,
        type: CLIENT_TYPE.BUSINESS,
      });
      if (!response.ok) {
        console.error('Error sending to Ops');
        setSendingToOps(false);
        return;
      }
      window.location.reload();
    } catch (error) {
      console.error('Error sending to Ops:', error);
      setSendingToOps(false);
    }
    setSendingToOps(false);
  };

  const handleReject = async () => {
    setRejecting(true);
    try {
      const response = await adminPost(URLS.REJECT_POTENTIAL, {
        userIds: selectedIds,
        type: CLIENT_TYPE.BUSINESS,
        by: ROLE.SALES,
      });
      if (!response.ok) {
        console.error('Error rejecting');
        setRejecting(false);
        return;
      }
      window.location.reload();
    } catch (error) {
      console.error('Error rejecting:', error);
      setRejecting(false);
    }
    setRejecting(false);
  };

  const handleCreateAccount = async (user, event) => {
    event.stopPropagation();
    setUsersLoading(true);
    try {
      const response = await adminPost(URLS.CREATE_CLIENT_ACCOUNT, {
        type: CLIENT_TYPE.BUSINESS,
        user,
      });
      if (!response.ok) {
        console.error('Error inviting');
        setUsersLoading(false);
        return;
      }
      window.location.reload();
    } catch (error) {
      console.error('Error inviting:', error);
    }
  };

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={`px-3 py-1 rounded-lg transition-all border ${
            i === page
              ? 'bg-blue-500 text-white border-blue-500'
              : 'bg-white text-gray-600 border-gray-300 hover:bg-gray-100 hover:text-blue-500'
          }`}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };

  const applyFilters = () => {
    setAppliedFilters(filters);
    setPage(1);
  };

  const fetchComments = async (potentialBusinessId) => {
    try {
      setCommentsLoading(true);
      const response = await adminPost(URLS.GET_POTENTIAL_BUSINESSES_COMMENTS, {
        potentialBusinessId,
      });
      if (!response.ok) {
        console.error('Failed to fetch potential business comments.');
        return;
      }
      const data = await response.json();
      setComments(data.comments);
    } catch (error) {
      console.error('Error fetching potential businesses comments:', error);
    }
    setCommentsLoading(false);
  };

  const handleRowClick = async (user) => {
    const newUser = user;
    if (!user.skipOpsReview) {
      newUser.skipOpsReview = false;
    }
    setSelectedUser(newUser);
    await fetchComments(newUser.id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleSaveChanges = async (updatedUser) => {
    try {
      const response = await adminPost(URLS.UPDATE_POTENTIAL_BUSINESS, {
        updatedUser,
      });
      if (!response.ok) {
        console.error('Error updating user');
        return;
      }
      window.location.reload();
    } catch (error) {
      console.error('Error updating user:', error);
    }
    handleCloseModal();
  };

  const handleWriteComment = async () => {
    try {
      const response = await adminPost(URLS.ADD_POTENTIAL_BUSINESS_COMMENT, {
        comment,
        potentialClientId: selectedUser.id,
      });
      if (!response.ok) {
        console.error('Error adding comment to potential business.');
        return;
      }
      const newComment = await response.json();
      setComments((prevComments) => [newComment, ...prevComments]);
      setComment('');
    } catch (error) {
      console.error('Error adding comment to potential business: ', error);
    }
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <Link
        to="/admin/dashboard"
        className="inline-block mb-4 text-blue-600 hover:underline"
      >
        Back To Dashboard
      </Link>
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">
        Potential Business Clients
      </h2>
      <Link
        className="px-4 py-2 mb-4 bg-blue-600 text-white rounded hover:bg-blue-700"
        to="/admin/potential-business-clients/add"
      >
        Add Potential Business
      </Link>
      <div className="mt-4 text-gray-700">
        *Ideal path for potential clients:{' '}
        <b className="text-blue-600">INITIALIZED</b> ➡️{' '}
        <b className="text-blue-600">OPS_REVIEW</b> ➡️{' '}
        <b className="text-blue-600">SALES_REVIEW</b> ➡️{' '}
        <b className="text-blue-600">ACCOUNT_CREATED</b>
      </div>
      <hr className="my-6" />
      <div className="mb-6">
        <button
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
          onClick={applyFilters}
        >
          Apply Filters
        </button>
        <div className="mt-4 flex flex-wrap gap-4">
          {['myClients', 'unclaimed', 'othersClients'].map((filter) => (
            <label key={filter} className="flex items-center gap-2">
              <input
                type="checkbox"
                name={filter}
                checked={filters[filter]}
                onChange={handleFilterChange}
                className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
              <span className="text-gray-700 capitalize">
                {filter === 'myClients'
                  ? 'My Clients'
                  : filter === 'unclaimed'
                    ? 'Unclaimed'
                    : "Others' Clients"}
              </span>
            </label>
          ))}
        </div>
      </div>
      <hr className="my-6" />
      {sendingToOps || rejecting ? (
        <div className="text-center text-gray-500">Loading...</div>
      ) : (
        <div className="flex gap-4 mb-6">
          <button
            className="px-4 py-2 bg-yellow-600 text-white rounded hover:bg-yellow-700 disabled:opacity-50"
            onClick={handleSendToOps}
            disabled={selectedIds.length === 0}
          >
            Send to Ops
          </button>
          <button
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 disabled:opacity-50"
            onClick={handleReject}
            disabled={selectedIds.length === 0}
          >
            Reject
          </button>
        </div>
      )}
      {usersLoading ? (
        <div className="flex justify-center items-center h-32">
          <Spinner />
        </div>
      ) : !users.length ? (
        <p className="text-center text-gray-500">No potential businesses.</p>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="table-auto w-full border-collapse border border-gray-300 bg-white shadow-sm rounded-lg">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 border border-gray-300"></th>
                  <th className="px-4 py-2 border border-gray-300">Action</th>
                  <th className="px-4 py-2 border border-gray-300">Status*</th>
                  <th className="px-4 py-2 border border-gray-300">
                    Business Name
                  </th>
                  <th className="px-4 py-2 border border-gray-300">
                    Business Title
                  </th>
                  <th className="px-4 py-2 border border-gray-300">Email</th>
                  <th className="px-4 py-2 border border-gray-300">
                    Phone Number
                  </th>
                  <th className="px-4 py-2 border border-gray-300">Address</th>
                  <th className="px-4 py-2 border border-gray-300">City</th>
                  <th className="px-4 py-2 border border-gray-300">State</th>
                  <th className="px-4 py-2 border border-gray-300">Zip Code</th>
                  <th className="px-4 py-2 border border-gray-300">
                    Contact Title
                  </th>
                  <th className="px-4 py-2 border border-gray-300">
                    Daily Patrons
                  </th>
                  <th className="px-4 py-2 border border-gray-300">
                    Square Footage
                  </th>
                  <th className="px-4 py-2 border border-gray-300">Created</th>
                  <th className="px-4 py-2 border border-gray-300">
                    Referred By
                  </th>
                  <th className="px-4 py-2 border border-gray-300">
                    Sales Rep
                  </th>
                  <th className="px-4 py-2 border border-gray-300">Ops Rep</th>
                  <th className="px-4 py-2 border border-gray-300">
                    Installer
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr
                    key={user.id}
                    className="hover:bg-gray-100 transition cursor-pointer"
                    onClick={() => handleRowClick(user)}
                  >
                    <td className="px-4 py-2 border border-gray-300 text-center">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 border-gray-300 rounded cursor-pointer"
                        disabled={[
                          POTENTIAL_CLIENT_STATUS.OPS_REVIEW,
                          POTENTIAL_CLIENT_STATUS.ACCOUNT_CREATED,
                        ].includes(user.status)}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) => {
                          event.stopPropagation();
                          handleCheckboxChange(user.id);
                        }}
                        checked={selectedIds.includes(user.id)}
                      />
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      <button
                        className="px-3 py-1 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
                        onClick={(event) => handleCreateAccount(user, event)}
                        disabled={
                          user.status !== POTENTIAL_CLIENT_STATUS.SALES_REVIEW
                        }
                      >
                        Create Account
                      </button>
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.status === POTENTIAL_CLIENT_STATUS.CREATED
                        ? POTENTIAL_CLIENT_STATUS.INITIALIZED
                        : user.status}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.businessName}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.title}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.email}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.phoneNumber}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.address}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.city}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.state}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.zipCode}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.contactTitle}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.dailyPatrons}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.squareFootage}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {getFormattedDate(user.created)}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.referredBy}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.createdBy}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.opsUser}
                    </td>
                    <td className="px-4 py-2 border border-gray-300">
                      {user.installer}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-center mt-6">
            <button
              onClick={prevPage}
              disabled={page === 1}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-gray-300"
            >
              Prev
            </button>
            <div className="flex space-x-2">{renderPageNumbers()}</div>
            <button
              onClick={nextPage}
              disabled={page === totalPages}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-gray-300"
            >
              Next
            </button>
          </div>
        </>
      )}
      {isModalOpen && (
        <CustomModal onClose={handleCloseModal}>
          <EditPotentialBusiness
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            comments={comments}
            comment={comment}
            setComment={setComment}
            commentsLoading={commentsLoading}
            allInstallers={allInstallers}
            handleWriteComment={handleWriteComment}
            handleSaveChanges={handleSaveChanges}
            handleCloseModal={handleCloseModal}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default PotentialBusinessClients;
