import SmallSpinner from './SmallSpinner';
import { getFormattedDate } from '../pages/admin/utils';

const EditPotentialBusinessOps = ({
  selectedUser,
  comment,
  setComment,
  handleWriteComment,
  commentsLoading,
  comments,
  handleCloseModal,
}) => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      {selectedUser && (
        <div>
          <h3 className="text-xl font-semibold mb-4">Potential Client Info:</h3>
          <p className="mb-2">
            <span className="font-semibold">Status:</span> {selectedUser.status}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Business Name:</span>{' '}
            {selectedUser.businessName}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Full Name:</span>{' '}
            {selectedUser.contactTitle}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Email:</span> {selectedUser.email}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Phone:</span>{' '}
            {selectedUser.phoneNumber}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Address:</span>{' '}
            {selectedUser.address}, {selectedUser.city}, {selectedUser.state}{' '}
            {selectedUser.zipCode}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Sales Rep:</span>{' '}
            {selectedUser.createdBy}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Ops User:</span>{' '}
            {selectedUser.opsUser ?? 'N/A'}
          </p>
          <p className="mb-4">
            <span className="font-semibold">Installer:</span>{' '}
            {selectedUser.installer ?? 'N/A'}
          </p>

          <h3 className="text-lg font-semibold mb-2">Comments:</h3>
          <label className="block mb-4">
            <textarea
              className="w-full p-2 border rounded-md mb-2"
              placeholder="Add comments here."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <br />
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-400"
              disabled={!comment.length}
              type="button"
              onClick={handleWriteComment}
            >
              Comment
            </button>
          </label>

          <div>
            {commentsLoading ? (
              <SmallSpinner />
            ) : (
              comments.map((comment) => (
                <div
                  className="bg-gray-100 p-4 rounded-md mb-4"
                  key={comment.id}
                >
                  <p className="mb-2">{comment.value}</p>
                  <small className="block text-sm text-gray-500">
                    {getFormattedDate(comment.created)}
                  </small>
                  <small className="block text-sm text-gray-500">
                    Written by {comment.author}, Role: {comment.type}
                  </small>
                </div>
              ))
            )}
          </div>

          <button
            className="mt-4 px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
            type="button"
            onClick={handleCloseModal}
          >
            Exit
          </button>
        </div>
      )}
    </div>
  );
};

export default EditPotentialBusinessOps;
