import { adminPost } from '../../utils';
import { URLS } from '../../constants';

export const getFormattedDate = ({ _seconds, _nanoseconds }) => {
  const milliseconds = _seconds * 1000 + Math.round(_nanoseconds / 1000000);
  const date = new Date(milliseconds);
  return date.toLocaleString();
};

export const fetchPriceHistory = async () => {
  try {
    const response = await adminPost(URLS.GET_MOBILE_PRICE_HISTORY);
    if (!response.ok) {
      throw new Error('Failed to fetch price history');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching price history:', error);
    return null;
  }
};

export const getMobilePrice = (priceHistory, dateStr) => {
  try {
    if (!priceHistory) return null;

    const date = new Date(dateStr);
    const timestamp = Math.floor(date.getTime() / 1000);

    const availableTimestamps = Object.keys(priceHistory.data.points)
      .map(Number)
      .sort((a, b) => a - b);

    // Find the closest timestamp
    const closestTimestamp = availableTimestamps.reduce((prev, curr) => {
      return Math.abs(curr - timestamp) < Math.abs(prev - timestamp)
        ? curr
        : prev;
    });

    // Get the price data for that timestamp
    const priceData = priceHistory.data.points[closestTimestamp];
    return priceData.v[0];
  } catch (error) {
    console.error('Error getting MOBILE price:', error);
    return null;
  }
};

export const convertBonestoUSD = (bones, priceHistory, dateStr) => {
  const tokens = bones / 100000000; // Convert from bones to MOBILE tokens
  const priceInUsd = getMobilePrice(priceHistory, dateStr);

  if (priceInUsd !== null) {
    return tokens * priceInUsd;
  }
  return 0;
};
